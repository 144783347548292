import {
  Box,
  Flex,
  Icon,
  Link,
  Text,
  Button,
  Select,
  Input,
  Slider,
  SliderFilledTrack,
  SliderTrack,
  SliderThumb,
  useDisclosure,
  VStack,
  HStack,
} from "@chakra-ui/react";
import { MdArrowBack } from "react-icons/md";
import { ReactComponent as DropdownIcon } from "../../../../assets/icons/dropdown.svg";
import { ReactComponent as TrashIcon } from "../../../../assets/icons/trash.svg";
import { useState } from "react";
import DiskModal from "../components/DiskModal";

export default function DatabaseDetails({ data, onBack }) {
    const [disks, setDisks] = useState([
        {
          storageType: data["Storage Type"],
          storageCapacity: data["Storage Capacity"],
        },
      ]);
  const [cpuCores, setCpuCores] = useState(data.cpuCores || 1);
  const [ram, setRam] = useState(data.ram || 4);
  const [storage, setStorage] = useState(data["Storage Capacity"] || 0);
  
  const { isOpen, onOpen, onClose } = useDisclosure();
  const handleAddDisk = () => {
    setDisks([...disks, { storageType: "SSD", storageCapacity: "" }]);
  };

  const handleDeleteDisk = (index) => {
    const newDisks = disks.filter((_, i) => i !== index);
    setDisks(newDisks);
  };

  const handleDiskChange = (index, field, value) => {
    const newDisks = [...disks];
    newDisks[index][field] = value;
    setDisks(newDisks);
  };

  return (
    <Box>
      <Box position="absolute" top="3rem">
        <Link py={1} display="flex">
          {"Dashboard → Management → "}

          <Text fontWeight="bold">Databases → {data.id}</Text>
        </Link>
        <Box display="flex" alignItems="center">
          <Link
            href="#"
            display="flex"
            alignItems="center"
            justifyContent="center"
            onClick={onBack}
          >
            <Box
              backgroundColor="#363853"
              borderRadius="50%"
              p={1.5}
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <Icon as={MdArrowBack} color="white" w={5} h={5} />
            </Box>
          </Link>
          <Text fontWeight="bold" fontSize="35px" ml={3} color="#363853">
            Management - Databases
          </Text>
        </Box>
      </Box>
      <Flex justifyContent="space-between" py={4}>
        <Box width="48%">
          <VStack align="stretch" spacing={6}>
            <Box>
              <Text fontSize="lg" fontWeight="bold" mb={2} color="#464646">
                Database Information
              </Text>
              <VStack align="stretch" spacing={2}>
                <Flex justifyContent="space-between">
                  <Text>Database ID</Text>
                  <Text>{data.id}</Text>
                </Flex>
                <Flex justifyContent="space-between">
                  <Text>Account Number</Text>
                  <Text>{data.accountNumber}</Text>
                </Flex>
                <Flex justifyContent="space-between">
                  <Text>Name</Text>
                  <Text>{data.name}</Text>
                </Flex>
                <Flex justifyContent="space-between">
                  <Text>Region</Text>
                  <Text>{data.region}</Text>
                </Flex>
              </VStack>
            </Box>

            <Box>
              <Text fontSize="lg" fontWeight="bold" mb={2} color="#464646">
                CPU Information
              </Text>
              <Flex mb={2} justifyContent="space-between" alignItems="center">
                <Text>CPU Cores</Text>
                <Input
                  value={cpuCores}
                  onChange={(e) =>
                    console.log("CPU Cores Changed", e.target.value)
                  }
                  bg="white"
                  px={8}
                  py={1}
                  borderRadius={5}
                  width="20%"
                />
              </Flex>
              <Flex
                alignItems="center"
                justifyContent="space-between"
                width="100%"
                pt={2}
              >
                <Text mr={2}>1</Text>
                <Slider
                  value={cpuCores}
                  min={1}
                  max={16}
                  step={1}
                  onChange={(val) => setCpuCores(val)}
                  colorScheme="purple"
                  width="60%"
                >
                  <SliderTrack bg="purple.100">
                    <SliderFilledTrack bg="#422AFB" />
                  </SliderTrack>
                  <SliderThumb boxSize={5} />
                </Slider>
                <Text ml={2}>16</Text>
              </Flex>
            </Box>
            <Box mb={5}>
              <Text fontSize="lg" fontWeight="bold" mb={2}>
                Memory Information
              </Text>
              <Flex mb={2} justifyContent="space-between" alignItems="center">
                <Text width="95%">RAM</Text>{" "}
                <Input
                  value={ram + "GB"}
                  onChange={(e) => console.log("RAM Changed", e.target.value)}
                  bg="white"
                  textAlign="center"
                  borderRadius={5}
                  width="-moz-fit-content"
                />
              </Flex>
              <Flex justifyContent="space-between" alignItems="center" pt={2}>
                <Text mr={2}>0 GB</Text>
                <Slider
                  value={ram}
                  min={0}
                  max={64}
                  step={1}
                  onChange={(val) => setRam(val)}
                  colorScheme="purple"
                  width="60%"
                >
                  <SliderTrack bg="purple.100">
                    <SliderFilledTrack bg="#422AFB" />
                  </SliderTrack>
                  <SliderThumb boxSize={5} />
                </Slider>
                <Text ml={2}>64 GB</Text>
              </Flex>
            </Box>
            <Box mb={5}>
              <Text fontSize="lg" fontWeight="bold" mb={2}>
                Miscellaneous Information
              </Text>
              <Flex mb={2} justifyContent="space-between">
                <Text>URL</Text> <Text ml={2}>http://www.company.com</Text>
              </Flex>
            </Box>
          </VStack>
        </Box>

        <Box width="48%">
          <VStack align="stretch" spacing={6}>
          <Box mb={5}>
            <Text fontSize="lg" fontWeight="bold" mb={2}>
              Storage Information
            </Text>
            {disks.map((disk, index) => (
              <Flex
                key={index}
                mb={2}
                gap={4}
                justifyContent="space-between"
                alignItems="center"
              >
                <Select
                  value={disk.storageType}
                  onChange={(e) =>
                    handleDiskChange(index, "storageType", e.target.value)
                  }
                  bg="white"
                  borderRadius={5}
                  width="full"
                  icon={<DropdownIcon />}
                >
                  <option value="SSD">SSD</option>
                  <option value="HDD">HDD</option>
                </Select>
                <Input
                  value={disk.storageCapacity}
                  onChange={(e) =>
                    handleDiskChange(index, "storageCapacity", e.target.value)
                  }
                  bg="white"
                  borderRadius={5}
                  width="full"
                />
                <Box
                  bg="white"
                  borderRadius={5}
                  p={2.5}
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  cursor="pointer"
                  onClick={() => handleDeleteDisk(index)}
                >
                  <Icon as={TrashIcon} w={5} h={5} />
                </Box>
              </Flex>
            ))}
            <Flex justifyContent="space-between" alignItems="center" py={2}>
              <Text mr={2}>0 GB</Text>
              <Slider
                value={storage}
                min={0}
                max={1024}
                step={10}
                onChange={(val) => setStorage(val)}
                colorScheme="purple"
                width="60%"
              >
                <SliderTrack bg="purple.100">
                  <SliderFilledTrack bg="#422AFB" />
                </SliderTrack>
                <SliderThumb boxSize={5} />
              </Slider>
              <Text ml={2}>64 GB</Text>
            </Flex>
            <Flex mt={2} width="full">
              <Button
                bg="#EDEBFF"
                color="#422AFB"
                _hover={{ bg: "#EDEBFF" }}
                _active={{ bg: "#d2c7ff" }}
                variant="solid"
                mr={2}
                borderRadius={8}
                width="full"
                fontSize="sm"
                onClick={handleAddDisk}
              >
                Add New Disk
              </Button>
              <Button
                bg="white"
                color="#422AFB"
                border="1px solid #5c18e1"
                _hover={{ bg: "#f3e8ff" }}
                _active={{ bg: "#e1d5ff" }}
                variant="outline"
                borderRadius={8}
                width="full"
                fontSize="sm"
                onClick={onOpen}
              >
                View All Disks
              </Button>
              <DiskModal
                isOpen={isOpen}
                onClose={onClose}
                disks={disks}
                handleDiskChange={handleDiskChange}
                handleDeleteDisk={handleDeleteDisk}
                handleAddDisk={handleAddDisk}
              />
            </Flex>
          </Box>

            <Box>
              <Text fontSize="lg" fontWeight="bold" mb={2} color="#464646">
                Database Setup Cost
              </Text>
              <Flex mb={2} justifyContent="space-between">
                <Text>Cores - </Text> <Text ml={2}>{data.cpuCores}</Text>
              </Flex>
              <Flex mb={2} justifyContent="space-between">
                <Text>RAM - </Text> <Text ml={2}>{data.ram}</Text>
              </Flex>
            </Box>
          </VStack>
        </Box>
      </Flex>
      <Button
        variant="solid"
        size="md"
        px="1.2rem"
        width="full"
        py="1.4rem"
        borderRadius={5}
        color="white"
        fontWeight="light"
        bg="#422AFB"
        _hover={{ bg: "#422AFB" }}
        mt={4}
      >
        Update Rules
      </Button>
    </Box>
  );
}
