import React, { useState,useEffect } from "react";
import {
  Flex,
  Input,
  Box,Icon,
  SimpleGrid,
  InputGroup,
  InputLeftElement,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Button,
} from "@chakra-ui/react";
import { ReactComponent as SearchIcon } from '../../../assets/icons/search.svg';
import { ChevronDownIcon } from "@chakra-ui/icons";
import { DashBoardTable } from "components/table/Table";
import { useLocation } from "react-router-dom";
import { useGetOrders } from "features/Orders/useGetOrders";
import CreateOrderForm from "components/OnboardForm/CreateOrderForm";

const heads = [
  "Account Name",
  "Account Number",
  "Order Status",
  "Partner ID",
  "Submitted By",
  "Transaction Date",
  "Transaction ID",
];

const Orders = ({ isCreate, toggleCreate }) => {
  const [selectedOption, setSelectedOption] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const location = useLocation();
  const currentDate = new Date();
  const oneMonthBefore = new Date(currentDate);
  oneMonthBefore.setMonth(oneMonthBefore.getMonth() - 1);
  oneMonthBefore.setDate(1);
  const omb_date = oneMonthBefore.toISOString().split("T")[0];
  const queryParams = new URLSearchParams(location.search);
  const account_number = queryParams.get("accountNumber");
  const start_date = queryParams.get("startDate");
  const end_date = queryParams.get("endDate");

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleMenuItemClick = (option) => {
    setSelectedOption(option);
    toggleCreate();
  };

  // const {
  // 	isLoading: fetchingOrders,
  // 	orders,
  // 	results,
  // 	refetch,
  // } = useGetOrders({
  // 	account_number,
  // 	start_date: start_date || omb_date,
  // 	end_date: end_date || new Date().toISOString().split("T")[0],
  // });
  // useEffect(() => {
  // 	refetch();
  // }, [location.search, refetch]);

  return (
    <Box pt={{ base: "135px", md: "100px", xl: "100px" }} px={{ md: "15px" }}>
      <Flex justifyContent="space-between" mb={4} alignItems="center">
        {!isCreate && (
          <InputGroup width={{ base: "100%", md: "450px" }}>
            <InputLeftElement>
              <Icon as={SearchIcon}/>
            </InputLeftElement>
            <Input
              placeholder="Search..."
              value={searchTerm}
              bg="white"
              color="navy.700"
              border="none"
              onChange={handleSearchChange}
              marginRight={4}
            />
          </InputGroup>
        )}
        <Flex justifyContent="flex-end" flex="1">
          {!isCreate && (
            <Menu>
              <MenuButton
                as={Button}
                rightIcon={<ChevronDownIcon />}
                size="md"
                px={"1.2rem"}
                py={"1.4rem"}
                borderRadius={5}
                bg={"#422AFB"}
                color="white"
                fontWeight="light"
                _hover={{ bg: "#422AFB", opacity: 1 }}
                _active={{ bg: "#422AFB", opacity: 1 }}
              >
                Create New Order
              </MenuButton>
              <MenuList bg="white" border="none">
                <MenuItem
                  _hover={{ bg: "#0D55CA", color: "white" }}
                  color="gray.700"
                  onClick={() => handleMenuItemClick("ACS")}
                >
                  Cloud Storage
                </MenuItem>
                <MenuItem
                  _hover={{ bg: "#0D55CA", color: "white" }}
                  color="gray.700"
                  onClick={() => handleMenuItemClick("Virtual Machine")}
                >
                  Virtual Machine
                </MenuItem>
                <MenuItem
                  _hover={{ bg: "#0D55CA", color: "white" }}
                  color="gray.700"
                  onClick={() => handleMenuItemClick("Relational Database")}
                >
                  Relational Database
                </MenuItem>
              </MenuList>
            </Menu>
          )}
        </Flex>
      </Flex>

      {isCreate ? (
        <CreateOrderForm
          selectedOption={selectedOption}
          setCreated={toggleCreate} 
        />
      ) : (
        <>
          <SimpleGrid columns={{ base: 1, md: 1, xl: 1 }} gap="30px" mb="30px">
            <DashBoardTable heads={heads} data={[]} />
          </SimpleGrid>
        </>
      )}
    </Box>
  );
};

export default Orders;